import { BlankState, StateChanges } from './stateChanges'
import { RunStates } from './runStates'
//function gtag () { window.dataLayer.push(arguments) }

const basicMutations = {
  [StateChanges.SET_ENTIRE_STATE](state, commitObj) {
    this.replaceState(JSON.parse(commitObj.newStateJson))
  },
  [StateChanges.ABBR_NOT_RUN]: function (state) {
    state.abbrApiState = RunStates.NOT_RUN
  },
  [StateChanges.ABBR_RUNNING](state) {
    state.abbrApiState = RunStates.RUNNING
  },
  [StateChanges.ABBR_FAILED]: function (state) {
    state.abbrApiState = RunStates.FAILED
  },
  [StateChanges.ABBR_TIMED_OUT]: function (state) {
    state.abbrApiState = RunStates.TIMED_OUT
  },
  [StateChanges.ABBR_COMPLETE]: function (state) {
    state.abbrApiState = RunStates.COMPLETE
  },

  [StateChanges.NAKDAN_NOT_RUN]: function (state) {
    state.nakdanApiState = RunStates.NOT_RUN
  },
  [StateChanges.NAKDAN_RUNNING](state) {
    state.nakdanApiState = RunStates.RUNNING
  },
  [StateChanges.NAKDAN_FAILED]: function (state) {
    state.nakdanApiState = RunStates.FAILED
  },
  [StateChanges.NAKDAN_TIMED_OUT]: function (state) {
    state.nakdanApiState = RunStates.TIMED_OUT
  },
  [StateChanges.NAKDAN_COMPLETE]: function (state) {
    state.nakdanApiState = RunStates.COMPLETE
  },


  [StateChanges.CITATION_NOT_RUN]: function (state) {
    state.citationApiState = RunStates.NOT_RUN
  },
  [StateChanges.CITATION_RUNNING](state) {
    state.citationApiState = RunStates.RUNNING
  },
  [StateChanges.CITATION_FAILED]: function (state) {
    state.citationApiState = RunStates.FAILED
  },
  [StateChanges.CITATION_TIMED_OUT]: function (state) {
    state.citationApiState = RunStates.TIMED_OUT
  },
  [StateChanges.CITATION_COMPLETE]: function (state) {
    state.citationApiState = RunStates.COMPLETE
  },
  [StateChanges.PARALLEL_NOT_RUN]: function (state) {
    state.parallelApiState = RunStates.NOT_RUN
  },
  [StateChanges.PARALLEL_RUNNING](state) {
    state.parallelApiState = RunStates.RUNNING
  },
  [StateChanges.PARALLEL_FAILED]: function (state) {
    state.parallelApiState = RunStates.FAILED
  },
  [StateChanges.PARALLEL_TIMED_OUT]: function (state) {
    state.parallelApiState = RunStates.TIMED_OUT
  },
  [StateChanges.PARALLEL_COMPLETE]: function (state) {
    state.parallelApiState = RunStates.COMPLETE
  },
  [StateChanges.DOWNLOAD_RUNNING](state) {
    state.downloadApiState = RunStates.RUNNING
  },
  [StateChanges.DOWNLOAD_COMPLETE]: function (state) {
    state.downloadApiState = RunStates.FAILED
  },
  [StateChanges.DOWNLOAD_FAILED]: function (state) {
    state.downloadApiState = RunStates.FAILED
  },
  [StateChanges.TRANSLITERATE_NOT_RUN]: function (state) {
    state.transliterateApiState = RunStates.NOT_RUN
  },
  [StateChanges.TRANSLITERATE_RUNNING](state) {
    state.transliterateApiState = RunStates.RUNNING
  },
  [StateChanges.TRANSLITERATE_FAILED]: function (state) {
    state.transliterateApiState = RunStates.FAILED
  },
  [StateChanges.TRANSLITERATE_TIMED_OUT]: function (state) {
    state.transliterateApiState = RunStates.TIMED_OUT
  },
  [StateChanges.TRANSLITERATE_COMPLETE]: function (state) {
    state.transliterateApiState = RunStates.COMPLETE
  },
  [StateChanges.BIBLE_SEARCH_NOT_RUN]: function (state) {
    state.bibleApiState = RunStates.NOT_RUN
  },
  [StateChanges.BIBLE_SEARCH_RUNNING](state) {
    state.bibleApiState = RunStates.RUNNING
  },
  [StateChanges.BIBLE_SEARCH_FAILED]: function (state) {
    state.bibleApiState = RunStates.FAILED
  },
  [StateChanges.BIBLE_SEARCH_TIMED_OUT]: function (state) {
    state.bibleApiState = RunStates.TIMED_OUT
  },
  [StateChanges.BIBLE_SEARCH_COMPLETE]: function (state) {
    state.bibleApiState = RunStates.COMPLETE
  },
  [StateChanges.SET_TEXT_FOR_API_CALL]: function (state, data) {
    state.textForApi = data
  },
  [StateChanges.SET_ABBR_RESULTS]: function (state, data) {
    const options = (element) => element.options.length > 0
    if (data.some(options)) {
      data.map(element => {
        state.abbrResults += element.options[0] ? '<strong>' + element.options[0] + '</strong>' : element.word
      })
    }
  },
  [StateChanges.SET_NAKDAN_RESULTS]: function (state, data) {
    state.nakdanApiResponse = data
    data.map(element => {
      state.textWithNikud += element.options[0] ? element.options[0].replace('|', '') : element.word.replace('|', '')
    })
  },
  [StateChanges.SET_TEXT_FOR_SYNOPSIS]: function (state, newText) {
    state.textForSynopsis = newText;
  },
  [StateChanges.SET_SYNOPSIS_FILE_RESULTS]: function (state, data) {
    state.synopsisFiles.push(data)
  },
  [StateChanges.SET_PARALLELS_DATA]: function (state, data) {
    state.parallelsData = data[0].data
    //state.numParallels = state.data.length
  },
  [StateChanges.SET_CITATIONS_DATA]: function (state, data) {
    state.citationsData = data//state.data.push(data)
  },
  [StateChanges.SET_TALMUD_DATA]: function (state, data) {
    state.citationsData.results = state.citationsData.results.concat(data)
  },
  [StateChanges.SET_MISHNA_DATA]: function (state, data) {
    state.citationsData.results = state.citationsData.results.concat(data)
  },
  [StateChanges.SET_DISPLAY_DATA]: function (state, data) {
    state.displayData = data
  },
  [StateChanges.SET_TRANSLITERATE_RESULTS]: function (state, data) {
    state.transliterateResults = data
  },
  [StateChanges.SET_BIBLE_SEARCH_RESULTS]: function (state, data) {
    state.bibleSearchResults = data
  },
  [StateChanges.RESET_STATE]: function (state) {
    Object.assign(state, JSON.parse(JSON.stringify(BlankState)))
  }

}

export default basicMutations
