
<template>
  <div class="parallels">
    <div class="list-results results-wrap mb-3  pt-4 h-100 overflow-auto">
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <div class="pt-4 px-3 pb-3 mb-4 bg-light skeleton-wrap">
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton class="mt-5 mb-1" width="50%"></b-skeleton>
          </div>
        </template>
        <div ref="results" class="h-auto mb-2 show-all">
          <div class="position-relative" v-for="(subgroup, j) in parallelResults" :key="j" ref="sourceItem">
            <div v-for="(item, index) in subgroup" :key="index" :class="{ 'inner-parallel-results': index > 0 }">
              <div class="p-3 mb-4 p-group bg-white">
                <div v-if="getLibraryLink(item)">
                  <a target="_blank" :href="getLibraryLink(item)" class="source-text">{{ item.compNameHe }}
                  </a>
                  <div class="rounded-pill text-dark bg-background d-inline-block mt-4 mb-1">
                    <i class="fas fa-link"></i>
                    {{ hebrew ? 'הדף יפתח בספריה של דיקטה' : 'Open in Dicta Library' }}
                  </div>
                </div>
                <div v-else-if="item.url">
                  <a class="d-block source-text" target="_blank" :href="item.url">{{ item.compNameHe }}
                  </a>
                  <div class="rounded-pill text-dark bg-background d-inline-block mt-4 mb-1">
                    <i class="fas fa-link"></i>
                    {{ hebrew ? 'הדף יפתח בספריא' : 'Open in Sefaria' }}
                  </div>
                </div>
                <div class="source-text" v-else>{{ item.compNameHe }}</div>
              </div>

              <!--
              <div class="f-narkis px-3" v-if="index === 0">
                {{ item.compMatchedText.substring(item.compStartChar, item.compStartChar + item.compTextLength) }}
              </div>-->
              <div class="cursor-pointer" @click="toggleList(j)">
                <b-btn variant="link" v-if="subgroup.length > 1 && index === 0 && !showAllResults"
                  class="p-0 bg-transparent shadow-none text-underline text-body" style="font-size:16px;">
                  {{ hebrew ? 'עוד אפשרויות' : 'More' }}
                </b-btn>
                <!--<b-btn variant="link" v-if="subgroup.length > 1 && index === subgroup.length - 1 && showAllResults"
                  class="read-less rounded-circle my-2 text-center p-0">
                  <i class="fas fa-angle-up"></i>
                </b-btn>-->
              </div>
            </div>
          </div>
        </div>
      </b-skeleton-wrapper>
      <div ref="noResults" class="no-results text-center position-relative mt-5"
        v-if="(!loading && (!data || data.length == 0))">
        <i class="fas fa-book d-block"></i>
        <div class="camera-wrap position-absolute">
          <b-btn @click="resetData" class="rounded-circle p-0 camera-btn text-dark bg-white"><i
              class="fas fa-camera"></i>
          </b-btn>
        </div>

        <h3 class="font-weight-bold mt-4 mb-1">{{ hebrew ? 'לא נמצא מקור' : 'No source found' }}</h3>
        <h3 class="text-muted">{{ hebrew ? 'נסו לצלם שוב או צלמו טקסט שונה' : 'Try again or search for a new source' }}
        </h3>
      </div>

      <server-failed-popup v-if="failed" v-model="failed" :msg="serverErrorMessage" />
    </div>
    <div class="camera-wrap position-fixed p-4">
      <b-btn @click="resetData" class="rounded-circle p-0 camera-btn text-dark" v-if="data.length > 0 && !loading">
        <i class="fas fa-camera"></i>
      </b-btn>
    </div>
  </div>

</template>

<script>
import { RunStates } from '@/store/runStates'
//import Spinner from '@/components/spinner.vue'
import _ from 'lodash'
import ServerFailedPopup from "@/components/ServerFailedPopup"
import axios from 'axios'
export default {
  name: 'ListResults',
  props: {

  },
  data() {
    return {
      showAll: false,
      showFullSource: false,
      comparedText: [],
      showLoader: false,
      synopsisFileIndex: -1,
      itemForChapter: {},
      searchedTextArr: [],
      matchedArr: [],
      parallelResults: [],
      activeSrcIndex: -1,
      showMetaData: false,
      showSynopsis: false,
      sefariaData: [],
      singleObjectClicked: false,
      sourcesToExclude: [],
      showEarly: false,
      numResults: 0,
      showAllResults: false,
      dictaLibraryBooks: [],
    }
  },
  components: { ServerFailedPopup },
  mounted() {
    this.formattedText()
    this.getDictaLibraryBooks()
  },
  methods: {
    getDictaLibraryBooks() {
      const axiosInstance = axios.create({ timeout: 60000 })
      //const LIBRARY_SERVER = 'https://dicta-library-data.netlify.app'
      const LIBRARY_SERVER = 'https://dicta-library-data.pages.dev'

      axiosInstance
        .get(LIBRARY_SERVER + '/books.json').then(response => {
          this.dictaLibraryBooks = response.data
        }).catch(function (error) {
          console.log(error)
        })
    },
    getLibraryLink(item) {
      if (item.compBookXmlId.includes('dicta-ocr')) {
        let sourceChapter = item.url.split('.')[0]
        let bookName = sourceChapter.split('-')[0]
        let index = this.dictaLibraryBooks.map(function (e) { return e.fileName; }).indexOf(bookName)
        if (index >= 0) {
          let libraryUrl = 'https://cloudflare--dicta-library-dev.netlify.app/book/' + bookName + '/' + sourceChapter
          return libraryUrl
        }
      }
    },
    toggleAllResults() {
      if (this.parallelResults && this.parallelResults.length > 0)
        this.showAll = !this.showAll
      this.$refs.results.scrollTop = 0
    },
    formattedText() {
      if (this.data.length > 0) {
        this.parallelResults = []
        this.matchedArr = []
        //this.searchedText = this.$store.state.textForApiCall.replace(/\s\s+/g, ' ').split('')  
        let tempText = JSON.parse(JSON.stringify(this.$store.state.textForApi))
        tempText = tempText.replace(/\s\s+/g, ' ').replace(/\n/g, " ")
        this.searchedTextArr = tempText.split('')
        if (tempText) {
          let sortedResultsArr = Object.keys(this.results)
          let sortedResultsArrVals = Object.values(this.results)
          sortedResultsArr = [sortedResultsArr[0]]
          sortedResultsArrVals = [sortedResultsArrVals[0]]
          if (this.showEarly) {
            sortedResultsArrVals.forEach((arr, index) => {
              let itemsWithYear = arr.filter(item => parseInt(item.year) > 0)
              let earliestYear = Math.min(...itemsWithYear.map(item => parseInt(item.year)))
              sortedResultsArrVals[index] = itemsWithYear.filter(item => {
                return parseInt(item.year) >= earliestYear && parseInt(item.year) <= earliestYear + 25
              })
            })
          }
          this.numResults = 0
          sortedResultsArrVals.forEach(arr => {
            this.numResults += arr.length
          })
          sortedResultsArr.forEach((el, index) => {
            let obj = el.replace(/\s\s+/g, ' ')
            let startText = tempText.indexOf(obj, 0) //obj.baseStartChar         
            let endText = tempText.indexOf(obj, 0) + obj.length //obj.baseStartChar         
            this.matchedArr.push({ 'sourceIndex': index, 'startPos': startText, 'endPos': endText })
          })


          this.matchedArr = this.matchedArr.sort((a, b) => (a.endPos > b.endPos) ? 1 : -1)
          this.matchedArr.forEach(element => {
            this.parallelResults.push(sortedResultsArrVals[element.sourceIndex])

          })
        }
      }
    },
    resetData() {
      this.$store.commit('RESET_STATE')
      this.$router.push({ name: "capture-image" })
    },
    toggleList(index) {
      this.showAllResults = !this.showAllResults
      const selectedList = this.$refs.sourceItem[index]
      selectedList.classList.toggle('show-all-parallels')
    }
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    failed: {
      get() {
        return this.$store.state.serverState === RunStates.FAILED ||
          this.$store.state.serverState === RunStates.TIMED_OUT
      },
      set() {
        this.$store.commit('LIBRARY_NOT_RUN')
        // this.synopsisFileIndex = -1        
      }
    },
    results() {
      return _.groupBy(this.sortedData, item => item.baseMatchedText)
    },
    data() {
      return this.$store.state.parallelsData
    },
    sortedData() {
      if (this.data.length > 0) {
        let sortedResults = this.data.filter(
          //filter also by bookXmlId
          item => (!item.compName.includes('Ben-Yehuda')
          ))
        //item => ((item.year >= this.range.fromYear &&  item.year <= this.range.toYear) || item.year === 'NA' || item.year === 'null')  && ( !item.identified || item.identified < 90))//[0].data;
        sortedResults = sortedResults.sort((a, b) => (a.compTextLength < b.compTextLength) ? 1 : -1)
        return sortedResults
      } else return []
    },

    loading() {
      return this.$store.state.parallelApiState === RunStates.RUNNING
    },
    complete() {
      return this.$store.state.parallelApiState === RunStates.COMPLETE
    }
  },
  watch: {
    sortedData() {
      this.formattedText()
    },
    data() {
      this.formattedText()
    }
  }
}
</script>
<style scoped lang="scss">
.parallels {
  height: calc(100vh - 215px);

  .skeleton-wrap {
    border-radius: 16px;

    .b-skeleton {
      background-color: rgba(0, 0, 0, 0.22);
    }

    .b-skeleton-text {
      height: 23px;
      border-radius: 12px;
    }

    .b-skeleton-animate-wave::after {
      background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.9), transparent);
    }
  }

  .camera-wrap {
    left: 0;
    bottom: 0;

    .camera-btn {
      width: 72px;
      height: 72px;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.34);
      background-color: #bfe1fe;
      font-size: 30px;

    }
  }

  .cursor-pointer {
    cursor: pointer;
  }



  .item-wrap {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
  }

  .p-group {
    border-radius: 16px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.14);
    border: solid 1px #d8d8d8;

    .rounded-pill {
      padding: 2px 10px 2px 13px;
    }

    .source-text {
      font-size: 18px;
    }

    a.source-text {
      color: var(--primary);
      text-decoration: none;
    }

  }

  .read-less,
  .read-more {
    width: 28px;
    height: 28px;
    line-height: 33px;
  }

  .inner-parallel-results {
    display: none;
  }

  .show-all-parallels {
    .read-less {
      display: inline-block !important;
      line-height: 30px;
    }

    .read-more {
      display: none;
    }

    .inner-parallel-results {
      display: block !important;
    }
  }

  .no-results {
    .fa-book {
      color: #bfe1fe;
      font-size: 90px;
    }

    .camera-wrap {
      right: -55px;
      top: 27px;
    }
  }
}
</style>
