<template>
  <b-modal
    v-model="show"
    centered
    id="alert-modal"
    size="sm"
    
    footer-class="border-top"
    body-class="py-3 px-4"
    ok-only
    :ok-title="hebrew ? 'סגור' : 'Cancel'"
  >
    <div class="text-center">
      {{msg}}
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "AlertModal",
  props: ["value", "msg"], 
  computed: {
    hebrew () {
      return this.$settings.hebrew
    },    
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  methods: {   
  }
}
</script>
<style lang="scss">

</style>