<template>
    <div class="results">
        <list-results></list-results>
    </div>
</template>
<script>
import ListResults from '@/components/LinkListResults.vue'
export default {
    name: "File",
    props: {},
    components: { ListResults },
    data() {
        return {}
    }
}
</script>
