import Vue from 'vue'
import Router from 'vue-router'
import Results from './views/Results.vue'
import CaptureImage from './views/CaptureImage'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'capture-image',
            component: CaptureImage
        },
        {
            path: '/results',
            name: 'results',
            component: Results
        }
    ]
})