<template>
  <div id="app" class="h-100 overflow-hidden">
    <div class="d-none d-md-block w-100 bg-primary" style="height:100vh;">
      <div class="bg-white" style="height:28px;">
        <dicta-header :englishSupported="false"></dicta-header>
      </div>
      <div class="pt-5  text-center text-white">
        <img class="d-block mx-auto mt-5" src="./assets/landing-logo.png" alt="Logo"
          style="width: 116px;height:116px;" />
        <h2 class="mt-2 mb-4">חיפוש מקור</h2>
        <div style="font-size:26px;">הכלי זמין בנייד בלבד</div>
      </div>
    </div>
    <Header />
    <div class="container illuminite-container d-md-none">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header'

export default {
  name: 'App',
  components: {
    Header
  },
  data() {
    return {
      clearText: false
    }
  },
  computed: {

  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'mft_narkisclassic';
  src: url('assets/fonts/mft_narkisclassic-regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'mft_narkisclassic';
  src: url('assets/fonts/MFTW_NarkisClassicBold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}

.f-narkis {
  font-family: Roboto, mft_narkisclassic, sans-serif;
  line-height: 1.5;
}
</style>
