<template>
  <b-modal
    id="mobile-how-modal"
    v-model="show" 
    ok-only 
    centered 
    size="lg" 
    footer-class="border-top d-none d-sm-block"
    header-class="d-none d-sm-block"
    :ok-title="hebrew ? 'סגור' : 'Close'"
    ok-variant="secondary"
    >
    <template slot="modal-header">{{hebrew ? 'איך זה עובד' : 'How it works'}}</template>
    <div class="mobile-modal-top bg-secondary position-fixed w-100 d-block d-sm-none">
      <div class="row">
        <div class="col-8">
          <h3>{{hebrew ? 'איך זה עובד' : 'How it works'}}</h3>
        </div>
        <div @click="show=false" class="close-icon col-4" :class="{'text-left': hebrew, 'text-right': !hebrew}">
          <i class="fas fa-times"></i>
        </div>
      </div>
    </div>    
    <div class="how-to-text" v-if="hebrew">
      <p>
      הכלי לאיתור ביטויים מאפשר להזין מילת חיפוש ולקבל את הביטויים הנפוצים בתנ״ך ובתלמוד שכוללים את אותה המילה.<br>
      לדוגמה: הזינו את המילה ״חיים״ וקבלו ״מים חיים״, ״בארץ החיים״, ״עץ חיים״ ועוד.
      </p>
      <p>מספר התוצאות שתקבלו יתייחס לכל צרוף מילים כאל ביטוי בודד. כל ביטוי יופיע בשורה כשלצידו מספר התוצאות שקיימות בהטיות שונות. רשימת ההטיות המלאה תוצג בלחיצה על השורה.
      </p>
      <p>כל הטיה היא קישור לכלי החיפוש בתנ״ך / בתלמוד, שם תוכלו לצפות בכל המופעים של ההטיה. כדי לראות את כל המופעים של ההטיות של הביטוי, לחצו על הכפתור ״כל התוצאות״ שמופיע בצד שמאל של השורה.</p>
      <p>התוצאות מוצגות גם בתצוגת גרף שמציג את הקשרים בין הביטוי לספרים בו הוא מופיע.</p>
      <p>
      <p>את התוצאות תוכלו לסנן בעזרת הסינונים הבאים שמופיעים מימין לתוצאות:</p>
      <ul>
        <li>מקורות: תוצאות מהתנ״ך או מהתלמוד.</li>
        <li>ניתן להגדיר את מספר המילים שיכלול הביטוי.</li>
      </ul>
    </div>
    <div v-else class="how-to-text">
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "about-dialog",
  props: ["value"],
  computed: {
    hebrew () {
      return this.$settings.hebrew
    }, 
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>
<style lang="scss">
  @media screen and (max-width: 767px) {
    #mobile-how-modal{
      -webkit-overflow-scrolling: touch;
      .modal-dialog {
        margin: 0!important;
      }
      .modal-content {
        border-radius: 0!important;
        height: 100vh;
        overflow-y: scroll;
        overflow-x:hidden;
        background:white;
      }
      .mobile-modal-top{
          height: 50px;
          top:0;
          right:0;
          padding:15px;
          z-index: 1;
          h3{text-decoration:none;}
      }
      .how-to-text{
          padding-top:50px;
          max-height: 88vh;
          overflow-y: scroll;
          overflow-x:hidden;
      }
      @media screen and (max-width: 667px) {
        .how-to-text{
          max-height: 83vh;
        }
      }      
    }
  }
</style>
<style scoped lang="scss">
.how-to-text {
  max-height: 70vh;
  overflow: auto;
  h3{
  text-decoration: underline;
  }
}
</style>
