
<template>
  <div class="h-100">
    <div class="w-100">
      <spinner v-if="ocrLoading"></spinner>
    </div>
    <camera v-if="cameraOn" @photo-taken="photoTaken" @photo-error="cameraOn = false"></camera>
    <camera-confirm v-if="confirming" :picture="picture" @confirmed="photoConfirmed"
      @cancelled="confirming = false; cameraOn = true">
    </camera-confirm>
    <img ref="img">
    <b-modal v-if="imageData.length > 0" :visible="imageData.length > 0" id="img-preview" size="lg"
      content-class="h-100" :title="hebrew ? 'סריקת טקסט מתמונה' : 'Scan Image'"
      body-class="text-center h-100 d-flex align-items-center p-0" ok-only @hidden="clearImageData" @ok="runOcr"
      :ok-title="hebrew ? 'החל' : 'Start'">
      <div class="image-preview h-100 d-flex" v-if="imageData.length > 0">
        <spinner v-if="ocrLoading"></spinner>
        <cropper :canvas="{
          minHeight: 0,
          minWidth: 0,
          maxHeight: 1600,
          maxWidth: 1600,
        }" check-orientation class="cropper" ref="cropper" :src="imageData" :max-height="limitations.maxHeight"
          :max-width="limitations.maxWidth" :min-height="limitations.minHeight" :min-width="limitations.minWidth"
          :size-restrictions-algorithm="percentsRestriction" :adjust-stencil="true" @ready="ready" />
      </div>
    </b-modal>

    <div id="pane-wrap" class="text-edit position-relative mt-3 pb-5  d-none d-md-block" v-hotkey="keymap">
      <textarea class="main-input-box text-right p-3 h-100" id="text-wrap" v-model="areaText" maxlength="10000"
        :placeholder="hebrew ? 'הזן טקסט כאן' : 'Enter Hebrew text here'" />
      <div class="clear-main-input-div" v-show="areaText.length" @click="areaText = ''">
        <i-times />
      </div>
      <b-btn variant="primary" class="bottom-button start-button position-absolute px-4" @click="runDemo">
        {{ hebrew ? "החל" : "Start" }}
      </b-btn>
      <b-btn variant="link" class="bg-white text-muted img-btn position-absolute bottom-button p-0" @click="openOcr">
        <i class="fas fa-camera"></i>
        <input @change="previewImage" id="ocr-input" type="file" name="image" accept="image/*" class="d-none" />
      </b-btn>
      <AlertModal v-if="showAlertModal" v-model="showAlertModal" :msg="alertMessage" />
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from "axios";
import { Actions } from "@/store/stateChanges";
import Spinner from "./spinner.vue";
import AlertModal from "./AlertModal";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import Camera from '@/components/Camera.vue'
import CameraConfirm from '@/components/CameraConfirm.vue'

export default {
  name: "MainInput",
  components: {
    CameraConfirm,
    Camera,
    Spinner,
    AlertModal,
    Cropper,
  },
  mounted() {
    this.$store.commit("RESET_STATE");
  },
  data() {
    return {
      areaText: "",
      showAlertModal: false,
      alertMessage: "",
      ocrLoading: false,
      imageData: "",
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      limitations: {
        minWidth: 25,
        minHeight: 10,
        maxHeight: 100,
        maxWidth: 100
      },
      image: null,
      cameraOn: true,
      confirming: false,
      picture: null,
      pictureBlob: null
    };
  },
  computed: {
    keymap() {
      return {
        "ctrl+enter": (event) => {
          event.preventDefault();
          this.runDemo();
        },
      };
    },
    apiText() {
      return this.$store.state.textForApi
    },
    hebrew() {
      return this.$settings.hebrew;
    }
  },
  methods: {
    percentsRestriction({ minWidth, minHeight, maxWidth, maxHeight, imageSize }) {
      return {
        maxWidth: (imageSize.width * (maxWidth || 100)) / 100,
        maxHeight: (imageSize.height * (maxHeight || 100)) / 100,
        minWidth: (imageSize.width * (minWidth || 0)) / 100,
        minHeight: (imageSize.height * (minHeight || 0)) / 100,
      };
    },
    previewImage(event) {
      this.ocrLoading = true
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {

        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.imageData = e.target.result;
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },
    ready() {
      this.ocrLoading = false
    },
    clearImageData() {
      document.getElementById('ocr-input').value = null
      this.imageData = ""
      this.coordinates.width = 0
      this.coordinates.height = 0
      this.coordinates.left = 0
      this.coordinates.top = 0
      this.image = null
    },
    runDemo() {
      if (this.areaText !== "") {
        this.$store.commit("RESET_STATE", this.areaText)
        this.$store.commit("SET_TEXT_FOR_API_CALL", this.areaText)
        this.$store.dispatch(Actions.RUN_PARALLELS)
      } else {
        this.showAlertModal = true;
        this.alertMessage = "הזן טקסט בבקשה";
      }
    },
    openOcr() {
      this.cameraOn = true
      // document.getElementById("ocr-input").click();
    },
    runOcr(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.ocrLoading = true
      let { canvas } = this.$refs.cropper.getResult()
      canvas.toBlob(blob => {
        this.sendToOcrServer(blob)
      })
    },
    sendToOcrServer(blob) {
      this.$router.push({ name: "results" })
      this.$store.commit('PARALLEL_RUNNING')
      const fd = new FormData();
      const file = new File([blob], "dot.png", blob);
      //fd.append('image', document.getElementById('ocr-input').files[0])
      fd.append("image", file);
      axios
        .post("https://segmentserver.dicta.org.il/ocr/", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.ocrLoading = false;
          this.areaText = response.data.text;
          this.runDemo()
          this.$nextTick(() => {
            this.$bvModal.hide('img-preview')
          })
        })
        .catch(() => (this.ocrLoading = false));
    },
    photoTaken(e) {
      this.cameraOn = false
      this.confirming = true
      this.picture = URL.createObjectURL(e)
      this.pictureBlob = e
    },
    photoConfirmed() {
      this.confirming = false
      URL.revokeObjectURL(this.picture)
      this.ocrLoading = true
      this.sendToOcrServer(this.pictureBlob)
    }
  },
  watch: {
    apiText(val) {
      if (val === '')
        this.areaText = ''
    }
  }
};
</script>
<style lang="scss">
@media (max-width: 991px) {

  html,
  body,
  #app {
    height: 100%;
    background-color: #f6f6f6;

    //overflow-y: hidden;
  }

  .tooltip {
    display: none !important;
  }
}

#img-preview {
  .modal-dialog {
    height: 100%;
    margin: 0;

    @media (min-width: 576px) {
      max-width: 100%;
    }

    .modal-content {
      background: black;
      overflow-y: scroll;

      header {
        background: black;
        border-bottom: 1px solid white;
        color: white;

        h5 {
          font-size: 22px;
        }

        button {
          left: 0;
          top: 11px;
          position: absolute;
          color: white;
          opacity: 1;
          text-shadow: none;
        }
      }

      .modal-body {
        //max-
        //height: calc(100vh - 180px)!important;
        height: calc(100%);
        //height: 90%;
        overflow-y: scroll;

        .image-preview {
          overflow: scroll;
          //background: red;
          width: 100%;
          //width: auto;
          margin: auto;
          //height:auto;
          //min-height: calc(100vh - 175px);
        }

        .cropper {
          max-height: 800vh;
          max-width: 80%;
          margin: auto;
          overflow-y: scroll;
        }
      }

      footer {
        button {
          font-size: 18px;
          height: 42px;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 3px;
          box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
          border: solid 1px #ffffff;
        }
      }
    }
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.text-edit {
  height: calc(100vh - 140px);
  border-radius: 6px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px #d8d8d8;

  @media (max-width: 991px) {
    height: calc(100% - 20px);

  }
}

/* DocumentSettings-specific CSS goes here */
.main-input-box {
  width: 100%;
  font-family: mft_narkisclassic, serif;
  font-size: 25px;
  line-height: 30px;
  border-style: none;
  resize: none;
  padding: 6px 39px 6px 6px;
}

.he .main-input-box {
  padding: 6px 6px 6px 39px;
}

.main-input-box:focus {
  outline: none;
}

.bottom-button {
  bottom: 12px;
}

.img-btn {
  bottom: 5px;

  i {
    font-size: 30px;
  }
}

.he .start-button,
.img-btn {
  left: 15px;
  right: auto;
}

.start-button,
.he .img-btn {
  right: 15px;
  left: auto;
}

.clear-main-input-div {
  position: absolute;
  top: 12px;
  left: 13px;
  height: 23px;
  width: 22px;
  text-align: center;
  cursor: pointer;
}
</style>
