<template>
  <b-modal
    id="keyboard"
    v-model="show" 
    ok-only 
    centered 
    size="lg" 
    footer-class="border-top d-none d-sm-block"
    body-class="py-0"
    header-class="d-none d-sm-block"
    :ok-title="hebrew ? 'סגור' : 'Close'"
    ok-variant="secondary"
    >
    <template slot="modal-header">{{hebrew ? 'קיצורי מקלדת' : 'Keyboard shortcuts'}}</template>
    <div class="mobile-modal-top bg-secondary position-fixed w-100 d-block d-sm-none">
      <div class="row">
        <div class="col-8">
          <h3>{{hebrew ? 'קיצורי מקלדת' : 'Keyboard shortcuts'}}</h3>
        </div>
        <div 
          @click="show=false" class="close-icon col-4" 
          :class="{'text-left': hebrew, 'text-right': !hebrew}">
          <i class="fas fa-times"></i>
        </div>
      </div>
    </div>    
    <div class="how-to-text" v-if="hebrew">      
        <div class="row py-2 bg-background">    
          <div class="col-4"><button class="btn btn-link">Ctrl</button> + <button class="btn btn-link">J</button></div>
          <div class="col-8">ניווט שמאלה בתוצאות</div>
        </div>
        <div class="row py-2">    
          <div class="col-4"><button class="btn btn-link">Ctrl</button> + <button class="btn btn-link">K</button></div>
          <div class="col-8">ניווט ימינה בתוצאות</div>
        </div>    
    </div>
    <div v-else class="how-to-text">
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "keyboard-shortcuts",
  props: ["value"],
  computed: {
    hebrew () {
      return this.$settings.hebrew
    }, 
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
}
</script>
<style scoped lang="scss">
    .col-4{direction: ltr;}
    button {
        border: 1px solid #d1d0d0;
        padding: 3px 10px;
    }
</style>
