import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueHotkey from 'v-hotkey'
import './plugins/bootstrap-vue'
import dictaVueComponents from 'dicta-vue-components'

Vue.use(VueHotkey)
Vue.use(router)
Vue.use(dictaVueComponents, { hebrew: true, useBodyClass: true })
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
