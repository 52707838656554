<template>
  <header class="ocr-header" :class="{ 'capture-page': $route.name === 'capture-image' }">
    <div class="first-header bg-primary py-3">
      <div class="container">
        <div class="dicta">DICTA</div>
        <div class="dicta-tagline">
          {{ hebrew ? 'כלים דיגיטליים לעיבוד טקסטים בעברית' : 'Analytical tools for Hebrew texts' }}
        </div>
      </div>
    </div>
    <div class="second-header position-relative">
      <div class="container">
        <div class="row">
          <div class="col-9  text-white">
            <div class="ocr-logo d-flex align-items-center">
              <div class=" d-inline-block text-center d-inline-block bg-white rounded-circle" @click="resetData">
                <img class="" src="/ms-icon-310x310.png" alt="Logo" />
              </div>
              <div class="d-inline-block mx-2">
                <h2 class="tool-name d-inline-block m-0">{{ hebrew ? 'חיפוש מקור' : 'Illuminate' }}</h2>
                <div class="tagline">{{ hebrew ? 'מקור לטקסט סרוק' : 'OCR' }}</div>
              </div>
            </div>
          </div>
          <div class="col-3 d-flex align-items-center">
            <div class=" d-block d-sm-none position-absolute mobile-menu-wrap">
              <mobile-menu :englishSupported="true"></mobile-menu>
            </div>
            <div class="d-none xd-sm-block" :class="{ 'text-left': hebrew, 'text-right': !hebrew }">
              <a id="keyboard-shortcuts" style="cursor:pointer" class="text-white m-3 border-bottom pt-1 d-inline"
                @click="showShortcuts = true">
                {{ hebrew ? 'קיצורי מקלדת' : 'Keyboard shortcuts' }}
              </a>
            </div>
            <!--   <b-button variant="primary" class="how-btn btn btn-outline-light btn-primary" size="md" @click="showAbout = true">
              {{hebrew ? 'איך זה עובד' : 'How it works'}}
            </b-button>   -->
          </div>
        </div>
      </div>
    </div>
    <keyboard-shortcuts v-model="showShortcuts"></keyboard-shortcuts>
    <about-dialog v-model="showAbout"></about-dialog>
  </header>
</template>
<script>
import AboutDialog from '@/components/HowDialog'
import KeyboardShortcuts from '@/components/KeyboardShortcuts'
export default {
  name: 'Header',
  components: {
    AboutDialog,
    KeyboardShortcuts
  },
  data() {
    return {
      showAbout: false,
      showShortcuts: false
    }
  },
  mounted() {
  },
  methods: {
    resetData() {
      this.$store.commit('RESET_STATE')
      if (this.$router.currentRoute.name !== 'capture-image')
        this.$router.push({ name: "capture-image" })

    }
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    }
  }
}    
</script>

<style lang="scss" >
.ocr-header {
  .dicta {
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 900;
    color: rgba(255, 255, 255, 0.96);
    opacity: 0.68;
    line-height: 29px;
  }

  .dicta-tagline {
    opacity: 0.94;
    color: white;
    line-height: 22px;
  }

  &.capture-page {
    position: absolute;
    z-index: 10;
    opacity: 0.8;
    top: 0;
    right: 0;
    width: 100%;

    .first-header {
      background-color: transparent !important;
    }

    @media (orientation: landscape) {
      .first-header {
        display: none;
      }
    }

    .second-header {
      display: none;
    }
  }

  .second-header {
    background-image: linear-gradient(to bottom, #3ba4fa -29%, #007ee5 89%);
  }

  .ocr-logo {
    height: 73px;
    //font-size: 22px;

    .d-inline-block {
      vertical-align: top;
    }

    h2 {
      color: rgba(255, 255, 255, 0.96);
    }

    .tagline {
      line-height: 20px;
      opacity: 0.65;
      color: rgba(255, 255, 255, 0.96);
    }


    .rounded-circle {
      height: 50px;
      width: 50px;
      cursor: pointer;

      img {
        max-width: 100%;
      }

    }
  }
}
</style>
<style lang="scss">
.mobile-menu-wrap,
.he .mobile-menu-wrap {
  border-radius: 8px;
  border: solid 1px #fff;
  width: 42px;
  height: 42px;
  line-height: 40px;
  right: 15px;
  left: auto;

  #mobile-toolbar-button {
    text-align: center;
    display: block;
  }
}

.he .mobile-menu-wrap {
  left: 15px;
  right: auto;
}
</style>