import backendRedirect from '../backendRedirect'
import { getDevServer, getDevServerRoot } from './devMode'


const SERVER_ROOT = '/api'
const ABBR_SERVER = backendRedirect.abbrApiTrafficManager + SERVER_ROOT
const NAKDAN_SERVER = backendRedirect.nakdanApiTrafficManager + SERVER_ROOT
const CITATION_SERVER = backendRedirect.citationApiTrafficManager + SERVER_ROOT
const TRANSLITERATE_SERVER = backendRedirect.transliterateApiTrafficManager + SERVER_ROOT
const BIBLE_SERVER = backendRedirect.bibleApiTrafficManager + '/search'// + SERVER_ROOT
const defaultServerWithoutRoot = backendRedirect.parallelApiTrafficManager
const devServerWithoutRoot = getDevServer('parallels', defaultServerWithoutRoot) // works b/c default url -
const devServerRoot = getDevServerRoot('parallels') // - i.e. apiTrafficManager - has NO "path" (e.g. "/server")
const serverRoot1 = devServerRoot || '/parallels' // note the url in the file must EXCLUDE "/api" - 
const serverRoot2 = '/api' // - which makes sense b/c the call to "output" doesn't use that
const PARALLEL_SERVER = (devServerWithoutRoot || defaultServerWithoutRoot) + serverRoot1 + serverRoot2
const SYNOPSIS_SERVER = backendRedirect.synopsisApiTrafficManager



export const AppConfig = {
  ABBR_SERVER: ABBR_SERVER,
  NAKDAN_SERVER: NAKDAN_SERVER,
  CITATION_SERVER: CITATION_SERVER,
  TRANSLITERATE_SERVER: TRANSLITERATE_SERVER,
  BIBLE_SERVER: BIBLE_SERVER,
  PARALLEL_SERVER: PARALLEL_SERVER,
  SYNOPSIS_SERVER: SYNOPSIS_SERVER
}
