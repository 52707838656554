<template>
  <div class="confirm">
    <div class="buttons">
      <button class="confirm-button" @click="$emit('confirmed')"><i class="fas fa-check"></i></button>
      <button class="confirm-button" @click="$emit('cancelled')"><i class="fas fa-times"></i></button>
    </div>
    <img class="picture" :src="picture">
  </div>
</template>

<script>
export default {
  name: "CameraConfirm",
  props: {
    picture: {}
  }
}
</script>

<style scoped lang="scss">
.confirm {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  color: white;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.buttons {
  display: flex;
  flex-direction: column;
}

@media (orientation: portrait) {
  .confirm {
    flex-direction: column-reverse;
  }

  .buttons {
    flex-direction: row-reverse;
  }
}

.confirm-button {
  border-radius: 50px;
  border: 2px solid white;
  padding: 20px;
  margin: 50px;

  @media (orientation: portrait) {
    margin: 0 12px 80px;
  }

  line-height: 0;
  background-color: white;
  color: black;
  font-size: 30px;
  width: 72px;
  height: 72px;
}

.picture {
  max-height: 100%;
  max-width: 100%;
  min-width: 100px;
  min-height: 100px;
  object-fit: contain;
  flex: auto;
}
</style>
