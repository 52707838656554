<template>
    <div class="results">
        <main-input></main-input>
    </div>
</template>
<script>
import MainInput from "@/components/MainInput"
export default {
    name: "File",
    props: {},
    components: { MainInput },
    data() {
        return {}
    }
}
</script>
