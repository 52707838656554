// from https://github.com/mdn/dom-examples/blob/master/pointerevents/Pinch_zoom_gestures.html

let callback

// Log events flag
let logEvents = false

// Global vars to cache event state
const evCache = []
let prevDiff = -1

// Logging/debugging functions
export function togglePinchLog() {
  logEvents = !logEvents;
}

function log(prefix, ev) {
  if (!logEvents) return;
  const s = prefix + ": pointerID = " + ev.pointerId +
    " ; pointerType = " + ev.pointerType +
    " ; isPrimary = " + ev.isPrimary
  console.log(s)
}

function pointerdown_handler(ev) {
  // The pointerdown event signals the start of a touch interaction.
  // This event is cached to support 2-finger gestures
  evCache.push(ev);
  log("pointerDown", ev);
}

function getDistance(a, b) {
  if (!b) return 0
  return Math.sqrt((b.clientX - a.clientX) ** 2 + (b.clientY - a.clientY) ** 2)
}


function pointermove_handler(ev) {
  // This function implements a 2-pointer horizontal pinch/zoom gesture.
  //
  // If the distance between the two pointers has increased (zoom in),
  // the target element's background is changed to "pink" and if the
  // distance is decreasing (zoom out), the color is changed to "lightblue".
  //
  // This function sets the target element's border to "dashed" to visually
  // indicate the pointer's target received a move event.
  log("pointerMove", ev);

  // Find this event in the cache and update its record with this event
  for (let i = 0; i < evCache.length; i++) {
    if (ev.pointerId === evCache[i].pointerId) {
      evCache[i] = ev;
      break;
    }
  }
  // If two pointers are down, check for pinch gestures
  if (evCache.length === 2) {
    // Calculate the distance between the two pointers
    const curDiff = getDistance(evCache[0], evCache[1])

    if (prevDiff > 0) {
      if (curDiff > prevDiff) {
        // The distance between the two pointers has increased
        log("Pinch moving OUT -> Zoom in", ev);
        callback(curDiff, prevDiff)
      }
      if (curDiff < prevDiff) {
        // The distance between the two pointers has decreased
        log("Pinch moving IN -> Zoom out",ev);
        callback(curDiff, prevDiff)
      }
    }

    // Cache the distance for the next move event
    prevDiff = curDiff;
  }
}

function pointerup_handler(ev) {
  log(ev.type, ev);
  // Remove this pointer from the cache and reset the target's
  // background and border
  remove_event(ev);

  // If the number of pointers down is less than two then reset diff tracker
  if (evCache.length < 2) prevDiff = -1;
}

function remove_event(ev) {
  // Remove this event from the target's cache
  for (let i = 0; i < evCache.length; i++) {
    if (evCache[i].pointerId === ev.pointerId) {
      evCache.splice(i, 1);
      break;
    }
  }
}

export function initPinch(el, _callback) {
  callback = _callback
  // Install event handlers for the pointer target
  el.onpointerdown = pointerdown_handler;
  el.onpointermove = pointermove_handler;

  // Use same handler for pointer{up,cancel,out,leave} events since
  // the semantics for these events - in this app - are the same.
  el.onpointerup = pointerup_handler;
  el.onpointercancel = pointerup_handler;
  el.onpointerout = pointerup_handler;
  el.onpointerleave = pointerup_handler;
}
