import { Actions, StateChanges } from './stateChanges'
import { AppConfig } from '../appConfig'
import axios from 'axios'
import Vue from 'vue'


const axiosInstance = axios.create({ timeout: 60000 })
axiosInstance.defaults.headers = {
  'Content-Type': 'text/plain;charset=UTF-8'
}
const minRange = 22
const maxRange = 10000

export default {
  [Actions.RUN_NAKDAN]: function ({ commit, state, dispatch }) {
    commit(StateChanges.NAKDAN_RUNNING)
    const requestObj = {
      addmorph: false,
      data: state.textForApi,
      genre: "modern",
      keepmetagim: true,
      keepqq: false,
      nodageshdefmem: false,
      patachma: false,
      task: "nakdan"
    }
    axiosInstance.post(AppConfig.NAKDAN_SERVER, JSON.stringify(requestObj))
      .then(function (response) {
        commit(StateChanges.SET_NAKDAN_RESULTS, response.data)
        commit(StateChanges.NAKDAN_COMPLETE)
        dispatch(Actions.RUN_ABBR)
        dispatch(Actions.RUN_TRANSLITERATE)
      })
      .catch(function (error) {
        if (error.code === 'ECONNABORTED') {
          commit(StateChanges.NAKDAN_TIMED_OUT)
        } else {
          // eslint-disable-next-line no-console
          console.log(error)
          commit(StateChanges.NAKDAN_FAILED)
        }
      })
  },
  [Actions.RUN_ABBR]: function ({ commit, state }) {
    commit(StateChanges.ABBR_RUNNING)
    var textForApi = state.textWithNikud
    const requestObj = {
      task: 'nakdan',
      data: textForApi,
      genre: "rabbinic",
      keepmetagim: true,
      keepqq: false,
      nodageshdefmem: false,
      patachma: false,
    }
    axiosInstance.post(AppConfig.ABBR_SERVER, JSON.stringify(requestObj))
      .then(function (response) {
        commit(StateChanges.SET_ABBR_RESULTS, response.data)
        commit(StateChanges.ABBR_COMPLETE)
      })
      .catch(function (error) {
        if (error.code === 'ECONNABORTED') {
          commit(StateChanges.ABBR_TIMED_OUT)
        } else {
          // eslint-disable-next-line no-console
          console.log(error)
          commit(StateChanges.ABBR_FAILED)
        }
      })
  },
  [Actions.UPLOAD_FILES]: function ({ state, commit }) {
    const baseUrl = AppConfig.SYNOPSIS_SERVER
    const urlExtend = '/api/synopsis';
    //const urlExtendForResponse = '/api/response';
    const urlUpload = '/uploadfile';
    commit(StateChanges.PARALLEL_RUNNING)
    let groupedData = state.parallelsData
      .groupBy(x => x.baseMatchedText)
      .map((value, key) => ({ baseMatchedText: key, dataFiles: value }))
      .value();
    //state.textForSynopsis
    var index = groupedData.map(function (e) { return e.baseMatchedText; }).indexOf(state.textForSynopsis);
    let group = groupedData[index]
    if (group.dataFiles.length > 0) {
      group.dataFiles.unshift({
        fileName: '000.txt',
        txtSrc: group.baseMatchedText
      })
      axiosInstance.post(baseUrl + urlExtend + urlUpload + '/0').then((response) => {
        let sessionId = response.data.id
        let url = baseUrl + urlExtend + urlUpload + '/' + sessionId

        group.dataFiles.forEach((row, counter) => {
          let fileName, fileData
          let formData = new FormData()
          fileName = row.fileName ? row.fileName : row.compNameHe.replaceAll(':', '_').replaceAll(' ', '_') + '.txt'//.replace(/ /g, "_")
          fileData = row.txtSrc ? row.txtSrc : row.compMatchedText.substring(row.compStartChar, row.compStartChar + row.compTextLength)
          var blob = new Blob([fileData], { type: 'text/plain' });
          formData.append('file', blob, fileName, { type: 'text/plain' })
          axiosInstance.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
            if (response.data.uploads_failed.length) {
              commit(StateChanges.SERVER_FAILED)
              console.log('Error')
              commit(StateChanges.FOOTNOTES_COMPLETE)
            } else if (counter == group.dataFiles.length - 1) {
              const resultsUrl = baseUrl + urlExtend + '/' + sessionId
              let formDataSynopsis = new FormData()
              formDataSynopsis.append('Grouping', 'None')
              axiosInstance.post(resultsUrl, formDataSynopsis, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
                if (response.data.horizontal_output_url) {
                  baseUrl + response.data.horizontal_output_url.substr(1)
                  commit(StateChanges.SET_SYNOPSIS_FILE_RESULTS, { file: baseUrl + response.data.horizontal_output_url.substr(1), txt: group.baseMatchedText, data: response.data.top_items_alignment })
                  commit(StateChanges.FOOTNOTES_COMPLETE)
                }
              }).catch((error) => {
                if (error.code === 'ECONNABORTED') {
                  commit(StateChanges.SERVER_TIMED_OUT)
                } else {
                  commit(StateChanges.SERVER_FAILED)
                }
              })
            }
          })
            .catch(error => {
              if (error.code === 'ECONNABORTED') {
                commit(StateChanges.SERVER_TIMED_OUT)
              } else {
                commit(StateChanges.SERVER_FAILED)
              }
            })
        })
      }).catch(error => {
        console.log(error)
        if (error.code === 'ECONNABORTED') {
          commit(StateChanges.SERVER_TIMED_OUT)
        } else {
          commit(StateChanges.SERVER_FAILED)
        }
      })
    }

    /*   let formData = new FormData()
      formData.append('file', new Blob(['{"AllowOutliers":"true"}']), 'synopsis.settings.json', { type: 'text/plain' })
      const httpClient = axios.create()
      httpClient.defaults.timeout = 45000
      httpClient.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
        
  }) */

  },
  [Actions.RUN_PARALLELS]: function ({ commit, state }) {
    //if (source) {
    // source.cancel()
    //}
    //const CancelToken = axios.CancelToken
    //source = CancelToken.source()
    const serverUrl = AppConfig.PARALLEL_SERVER
    commit(StateChanges.PARALLEL_RUNNING)
    let textForApi = state.textForApi
    //let directMatch = state.directMatch
    if (textForApi) {
      //data:  textForApi
      let minthreshold = 10
      axios.post(serverUrl + '/findincorpus?minthreshold=' + minthreshold + '&maxdistance=4', 'text=' + textForApi) //,{ cancelToken: source.token })
        .then(function (response) {
          //commit(StateChanges.SET_FILE_TXT_RESULTS, response.data.txt)
          //commit(StateChanges.SET_FILE_DOC_RESULTS, response.data.docx)
          commit(StateChanges.SET_PARALLELS_DATA, response.data.results)
          //if (response.data.results[0].numParallels > 0)
          // dispatch(Actions.UPLOAD_FILES)
          //else
          commit(StateChanges.PARALLEL_COMPLETE)

        })
        .catch(function (error) {
          if (axios.isCancel(error)) {
            //commit(StateChanges.FOOTNOTES_COMPLETE)
          } else {
            if (error.code === 'ECONNABORTED') {
              commit(StateChanges.PARALLEL_TIMED_OUT)
            } else {
              commit(StateChanges.PARALLEL_FAILED)
            }
          }
        })
    } else {
      console.log('Invalid input.')
    }
  },
  [Actions.RUN_FOOTNOTES]: function ({ commit, state, dispatch }) {
    let textForApi = state.textForApi
    if (textForApi) {
      commit(StateChanges.CITATION_RUNNING)
      axiosInstance.post(AppConfig.CITATION_SERVER + '/markpsukim', JSON.stringify({
        data: textForApi,
        mode: 'tanakh',
        thresh: 0,
        fdirectonly: false
      }))
        .then(function (response) {
          commit(StateChanges.SET_CITATIONS_DATA, response.data)
          axiosInstance.post(AppConfig.CITATION_SERVER + '/markpsukim', JSON.stringify({
            data: textForApi,
            mode: 'mishna',
            thresh: 0,
            fdirectonly: false
          }))
            .then(function (response) {
              commit(StateChanges.SET_TALMUD_DATA, response.data.results)
              axiosInstance.post(AppConfig.CITATION_SERVER + '/markpsukim', JSON.stringify({
                data: textForApi,
                mode: 'talmud',
                thresh: 0,
                fdirectonly: false
              }))
                .then(function (response) {
                  commit(StateChanges.SET_MISHNA_DATA, response.data.results)
                  dispatch(Actions.RUN_GROUP_DATA)
                })
                .catch(function () {
                  commit(StateChanges.CITATION_FAILED)
                })
            })
            .catch(function () {
              commit(StateChanges.CITATION_FAILED)
            })
        })
        .catch(function () {
          commit(StateChanges.CITATION_FAILED)
        })
    } else {
      console.log('Invalid input.')
      commit(StateChanges.CITATION_COMPLETE)
    }
  },
  [Actions.RUN_GROUP_DATA]: function ({ commit, state, dispatch }) {
    state.citationsData.keepredundant = true
    axiosInstance.post(AppConfig.CITATION_SERVER + '/parsetogroups?smin=' + minRange + '&smax=' + maxRange,
      JSON.stringify(state.citationsData))
      .then(function (response) {
        commit(StateChanges.SET_DISPLAY_DATA, response.data)
        if (response.data && response.data.length > 0)
          dispatch(Actions.GET_SEFARIA_TEXT)
        else
          commit(StateChanges.CITATION_COMPLETE)
      }).catch(function () {
        commit(StateChanges.CITATION_FAILED)
      })
  },
  [Actions.DOWNLOAD_RESULTS]: function ({ commit, state }) {
    commit(StateChanges.DOWNLOAD_RUNNING)
    axiosInstance.post(AppConfig.CITATION_SERVER + '/wordoutput?smin=' + minRange + '&smax=' + maxRange,
      JSON.stringify(state.citationsData), { responseType: 'arraybuffer' })
      .then(function (response) {
        Date.prototype.yyyymmdd = function () {

          let mm = this.getMonth() + 1; // getMonth() is zero-based
          let dd = this.getDate();

          return [this.getFullYear(),
          (mm > 9 ? '' : '0') + mm,
          (dd > 9 ? '' : '0') + dd
          ].join('');
        };

        let date = new Date();

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', "DictaCitationFinder-" + date.yyyymmdd() + ".docx"); //or any other extension

        document.body.appendChild(link);
        link.click();
        commit(StateChanges.DOWNLOAD_COMPLETE)
      })
      .catch(function (error) {
        console.log(error)
        commit(StateChanges.DOWNLOAD_FAILED)
      })
  },
  [Actions.RUN_TRANSLITERATE]: function ({ commit, state }) {
    let textForApi = state.textWithNikud
    if (textForApi !== '') {
      commit(StateChanges.TRANSLITERATE_RUNNING)
      // if(source){
      //  source.cancel();
      // }
      // const CancelToken = axios.CancelToken;
      //source = CancelToken.source();
      axiosInstance.post(AppConfig.TRANSLITERATE_SERVER, JSON.stringify({
        data: textForApi
      })) // { cancelToken: source.token}
        .then(function (response) {
          commit(StateChanges.SET_TRANSLITERATE_RESULTS, response.data.res)
          commit(StateChanges.TRANSLITERATE_COMPLETE)
        })
        .catch(function (thrown) {
          if (axios.isCancel(thrown)) {
            // console.log('Request cancelled')
          }
          else {
            commit(StateChanges.TRANSLITERATE_FAILED)
            console.log('Error')
          }

        })
    } else {
      //window.history.replaceState( {} , '', location.href.split("?")[0] )
    }
  },
  [Actions.RUN_BIBLE_SEARCH]: function ({ commit, state }) {
    commit(StateChanges.BIBLE_SEARCH_RUNNING)
    const requestObj = {
      query: state.textForApi,
      books: {},
      lexemes: [],
      related: [],
      sort: "",
      wordForms: [],
      from: 0,
      size: 4
    }
    axiosInstance.post(AppConfig.BIBLE_SERVER,
      JSON.stringify(requestObj), { headers: { 'Content-Type': 'application/json' } })
      .then(function (response) {
        commit(StateChanges.SET_BIBLE_SEARCH_RESULTS, response.data.hits)
        commit(StateChanges.BIBLE_SEARCH_COMPLETE)
      }).catch(function () {
        commit(StateChanges.BIBLE_SEARCH_FAILED)
      })
  },
  [Actions.GET_SEFARIA_TEXT]: function ({ state, commit }) {
    let SEFARIA_SEARCH_API = 'https://www.sefaria.org/api/texts/'
    state.displayData.map(element => {
      element.matches.map(item => {
        let textForApi = encodeURIComponent(item.verseId.split('.').slice(-3).join('.'))
        if (item.mode === 'Talmud') {
          textForApi = textForApi.replace(/.([^.]*)$/, '$1') + '.1'
        }
        axiosInstance
          .get(SEFARIA_SEARCH_API + textForApi, {
          }).then(function (response) {
            //element.matches.sefariaText = response.data.text[parseInt(textForApi.split(".").pop())-1]
            let sefariaText = response.data.text[parseInt(textForApi.split(".").pop()) - 1]
            if (sefariaText)
              Vue.set(item, 'sefariaText', sefariaText)//[parseInt(textForApi.split(".").pop())-1]
            else {
              //
            }
            commit(StateChanges.CITATION_COMPLETE)
          }).catch(function () {
            console.log('error')
            commit(StateChanges.CITATION_COMPLETE)
          })

      })
    })
  }
}
