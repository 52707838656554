<template>
  <div class="camera" :style="boundStyle">
    <div class="camera-buttons">
      <div v-if="flashAvailable" @click="toggleFlash">
        <button v-if="flashOn" class="fa-fw fa-layers flash-button bg-transparent">
          <i class="small-bolt fas fa-bolt"></i><i class="fas fa-ban"></i>
        </button>
        <button v-else class="fa-fw fa-layers flash-button bg-transparent"><i class="fas fa-bolt"></i></button>
      </div>
      <button class="camera-button" @click="sayCheese"></button>
      <div v-if="flashAvailable" style="width: 40px; height: 40px;"></div>
    </div>

    <div v-if="error" class="video-holder">
      <div class="error">
        {{ error }}
      </div>

    </div>
    <div v-else class="video-holder" ref="videoHolder">
      <div class="portrait-flash-btn position-absolute" v-if="flashAvailable" @click="toggleFlash">
        <button v-if="flashOn" class="fa-fw fa-layers flash-button bg-transparent">
          <i class="small-bolt fas fa-bolt"></i><i class="fas fa-ban"></i>
        </button>
        <button v-else class="fa-fw fa-layers flash-button bg-transparent"><i class="fas fa-bolt"></i></button>
      </div>
      <!--v-else-->
      <video id="video" class="video" playsinline muted></video>
      <svg v-if="pictureDelay" class="delaySvg" height="100" width="100">
        <circle transform="rotate(-90, 50, 50)" class="delayCircle" cx="50" cy="50" r="45" stroke="#ffffff"
          stroke-width="5" fill="none" />
      </svg>
      <div class="video-frame top"></div>
      <div class="video-frame bottom"></div>
      <ul class="instructions bottom px-5 list-unstyled pt-3" :dir="hebrew ? 'rtl' : 'ltr'">
        <li class="mb-2">
          {{ hebrew ? 'הצב את הטקסט כך שרוחב הקו יהיה ברוחב התיבה הלבנה' :
              'Place your text such that the width of the line is in the width of the white box'
          }}
        </li>
        <li class="mb-2">
          {{ hebrew ? 'היעזר בקווים הצהובים כדי ליישר את התמונה' :
              'Use the yellow lines to properly align the image'
          }}
        </li>
        <li>
          {{ hebrew ? 'התמונה צריכה לכלול לפחות ארבע שורות טקסט' : 'The image should include at least 4 lines of text'
          }}
        </li>
      </ul>
      <div class="video-frame right"></div>
      <div class="video-frame left"></div>
      <div class="guidelines"></div>
      <div class="bracket left-bracket"></div>
      <div class="bracket right-bracket"></div>
      <button class="portrait-camera-button" @click="sayCheese"></button>
    </div>
  </div>
</template>
<script>
import { initPinch } from '@/js/pinch.js'
//import { Actions } from "@/store/stateChanges"

let canvas, streaming = false, zoom, lastZoomSet, track, capturer, minZoom, maxZoom, zoomStep
const X_PERCENT = 0.8
const Y_PERCENT = 0.3 // of the width, not of the height
const PIXEL_WIDTH = 1500 / X_PERCENT

export default {
  name: "Camera",
  data() {
    return {
      previewWidth: 640,
      flashAvailable: false,
      flashOn: false,
      error: null,
      pictureDelay: false
    }
  },
  computed: {
    boundStyle() {
      return {
        '--y-percentage': 'calc((100% - 0.3*' + this.previewWidth + 'px) / 2)'
      }
    },
    hebrew() {
      return this.$settings.hebrew
    }
  },
  methods: {
    async sayCheese() {
      const video = document.getElementById('video');
      this.pictureDelay = true
      await new Promise(resolve => { setTimeout(() => resolve(), 900) })
      if (window.ImageCapture) {
        const capabilities = await capturer.getPhotoCapabilities()
        capturer.takePhoto({
          fillLightMode: this.flashOn ? 'flash' : 'off',
          redEyeReduction: false,
          imageWidth: capabilities.imageWidth.max
        })
          .then(blob => this.emitPhoto(blob))
          .catch(error => {
            console.log(error)
            this.fallbackPhoto(video)
          })
      } else {
        this.fallbackPhoto(video)
      }
    },
    fallbackPhoto(video) {
      if (streaming) {
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        const context = canvas.getContext('2d')
        context.drawImage(video, 0, 0)
        canvas.toBlob(blob => this.emitPhoto(blob))
      } else {
        this.$emit('photo-error')
        if (track) {
          track.stop()
        }
      }
    },
    async emitPhoto(blob) {
      this.pictureDelay = false
      try {
        track.stop()
        track = null
        streaming = false
        // OK, somehow we got an image, and all we know is that we previewed it with brackets around a part of the image as follows:
        // the width of the image should have the right and left X percent removed - 10 at time of writing
        // the height should have Y percent removed, Y = 20 at time of writing, but... the height shown is only part of the
        // video, and dependent on the shape of the browser window. This means that we need to measure the box in which we
        // show the video, and work from there.
        const img = await createImageBitmap(blob)
        const FRAME = 10
        // calculate final width and height of the slice we're keeping
        const sliceWidth = PIXEL_WIDTH * X_PERCENT
        const sliceHeight = PIXEL_WIDTH * Y_PERCENT
        // the canvas will have a white border around it, FRAME pixels wide, which may improve OCR
        canvas.width = sliceWidth + 2 * FRAME
        canvas.height = sliceHeight + 2 * FRAME
        const ctx = canvas.getContext("2d")
        ctx.fillStyle = 'white'
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        // we define the slice based on the width of the image
        const sourceSliceHeight = img.width * Y_PERCENT
        // copy and slice and scale the image
        ctx.drawImage(
          img,
          img.width * (1 - X_PERCENT) / 2, // x position to start copying from the source image
          (img.height - sourceSliceHeight) / 2, // y position to start copying: half the slice is before the midpoint
          img.width * X_PERCENT, // width in source
          sourceSliceHeight,
          FRAME, FRAME, sliceWidth, sliceHeight
        )
        if (!navigator.vendor.startsWith('Apple')) {
          canvas.toBlob(blob =>
            this.$emit('photo-taken', blob),
            'image/webp'
          )
        } else {
          canvas.toBlob(blob =>
            this.$emit('photo-taken', blob)
          )
        }
      } catch {
        this.$emit('photo-error')
      }
    },
    updatePreviewWidth() {
      this.previewWidth = this.$refs.videoHolder.getBoundingClientRect().width
    },
    toggleFlash() {
      if (this.flashAvailable && track) {
        this.flashOn = !this.flashOn
        track.applyConstraints({ advanced: [{ torch: this.flashOn }] })
      }
    },
    async selectCamera() {
      const constraints = { audio: false, video: { facingMode: 'environment', zoom: zoom } };
      const savedCamera = localStorage.getItem('savedCamera')
      if (savedCamera) {
        // reset buggy savedCamera
        if (savedCamera.startsWith('['))
          localStorage.removeItem('savedCamera')
        else {
          constraints.video.deviceId = savedCamera
          delete constraints.video.facingMode
        }
      }
      let mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
      const devicesPromise = navigator.mediaDevices.enumerateDevices()
      track = mediaStream.getVideoTracks()[0]
      // now we have the default camera
      // if the browser has track.getCapabilities and there's more than one camera, we want to confirm we
      // have the best camera
      // if we don't have the getCapabilities API, then might as well go with the default
      if (track.getCapabilities) {
        const processCapabilities = track => {
          const cap = new Map(Object.entries(track.getCapabilities()))
          const hasTorch = cap.has('torch') && cap.get('torch')
          const score = (cap.has('facingMode') && cap.get('facingMode')[0] === 'environment' ? 8 : 0) +
            (cap.has('focusMode') && cap.get('focusMode').includes('continuous') ? 4 : 0) +
            (cap.has('zoom') ? 2 : 0) +
            (hasTorch ? 1 : 0)
          return {
            score, hasTorch, deviceId: cap.get('deviceId'), zoom: cap.get('zoom')
          }
        }
        let { score: bestScore, hasTorch: bestHasTorch, deviceId: bestDevice, zoom: bestZoom } = processCapabilities(track)
        let lastDevice = bestDevice
        if (bestScore !== 15 && !savedCamera) {
          const devices = (await devicesPromise).filter(device => device.kind === 'videoinput' &&
            device.deviceId !== bestDevice)
          if (devices.length > 0) {
            // try other cameras - can only have one open at a time
            for (let device of devices) {
              track.stop()
              mediaStream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: device.deviceId, zoom: zoom } })
              track = mediaStream.getVideoTracks()[0]
              lastDevice = device
              let { score, hasTorch, zoom: trackZoom } = processCapabilities(track)
              if (score > bestScore) {
                bestScore = score
                bestDevice = device.deviceId
                bestHasTorch = hasTorch
                bestZoom = trackZoom
              }
            }
            localStorage.setItem('savedCamera', bestDevice)
          }
          if (lastDevice !== bestDevice) {
            track.stop()
            mediaStream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: bestDevice, zoom: zoom } })
            track = mediaStream.getVideoTracks()[0]
          }
        }
        this.flashAvailable = bestHasTorch
        if (bestZoom) {
          maxZoom = bestZoom.max
          minZoom = bestZoom.min
          zoomStep = bestZoom.step
        }
      }
      if (window.ImageCapture) {
        // try a bunch of times in hopes that all phones will end up with the correct zoom
        track.applyConstraints({ advanced: [{ zoom }] }).catch(() => { })
        capturer = new window.ImageCapture(track)
        track.applyConstraints({ advanced: [{ zoom }] }).catch(() => { })
      }
      const video = document.querySelector('video')
      video.srcObject = mediaStream;
      video.onloadedmetadata = function () {
        video.play();
      };
      video.addEventListener('canplay', () => {
        if (!streaming) {
          canvas = document.createElement('canvas')
          streaming = true
        }
      }, false)
    }
  },
  mounted() {
    zoom = lastZoomSet = 1.5
    this.previewWidth = this.$refs.videoHolder.getBoundingClientRect().width
    initPinch(this.$refs.videoHolder, (newDistance, prevDistance) => {
      const scaleDiff = prevDistance ? newDistance / prevDistance : 1
      zoom = zoom * scaleDiff
      if (zoom < minZoom) zoom = minZoom
      if (zoom > maxZoom) zoom = maxZoom
      if (Math.abs(lastZoomSet - Math.round(zoom / zoomStep) * zoomStep) >= zoomStep) {
        lastZoomSet = zoom
        track.applyConstraints({ advanced: [{ zoom }] })
      }
    })
    window.addEventListener('resize', this.updatePreviewWidth)
    this.selectCamera().catch(err => {
      //this.$store.dispatch(Actions.RUN_PARALLELS)
      //this.$router.push({ name: "results" })
      this.error = err.valueOf() + '\n' + JSON.stringify(err, ['fileName', 'lineNumber', 'columnNumber', 'message', 'stack'], 4)
      console.log(err.name + ": " + err.message, err);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updatePreviewWidth)
  }
}
</script>

<style scoped>
.portrait-flash-btn {
  top: 100px;
  left: 20px;
  z-index: 10;
}

.camera {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  color: white;
  z-index: 1;
  display: flex;
  align-items: center;
  --x-percentage: 10%;
  /* this is an approximation, but it needs to be corrected with JS */
  --y-percentage: calc((100% - 30vw) / 2);
}


.camera-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}

@media (orientation: portrait) {
  .camera {
    flex-direction: column-reverse;
  }

  .camera-buttons {
    display: none;
  }
}

.camera-button {
  border-radius: 50px;
  border: 25px solid white;
  padding: 0;
  margin: 50px;
}

.portrait-camera-button {
  border-radius: 50px;
  height: 68px;
  width: 68px;
  border: 7px solid rgba(216, 216, 216, 0.9);
  padding: 0;
  position: absolute;
  bottom: 20px;
  left: 50%;
  margin-left: -34px;
}

@media (orientation: landscape) {

  .portrait-flash-btn,
  .portrait-camera-button {
    display: none;
  }
}

.bracket {
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  width: 20px;
  height: calc(100% - var(--y-percentage) * 2);
  position: absolute;
  top: var(--y-percentage);
}

.left-bracket {
  left: var(--x-percentage);
}

.right-bracket:before,
.right-bracket:after,
.left-bracket:before,
.left-bracket:after {
  position: absolute;
  top: -2px;
  left: 0;
  height: 20px;
  width: 3px;
  background: #fff;
  content: '';
}

.right-bracket:after,
.right-bracket:before {
  left: 100%;
  left: calc(100% - 3px);
}

.left-bracket:after,
.right-bracket:after {
  top: calc(100% - 19px);
}

.guidelines {
  left: calc(var(--x-percentage) + 7%);
  top: calc(var(--y-percentage) + 25px);
  position: absolute;
  border-top: 4px solid #ffde00;
  border-bottom: 4px solid #ffde00;
  height: calc((100% - var(--y-percentage) * 2) - 50px);
  width: calc(86% - var(--x-percentage) * 2);
}

.guidelines:after {
  position: absolute;
  top: 50%;
  margin-top: -2px;
  height: 4px;
  width: 100%;
  background: #ffde00;
  content: '';
}

.right-bracket {
  right: var(--x-percentage);
}

.video-holder {
  position: relative;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  touch-action: none;
}

.video {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.video-frame {
  position: absolute;
  background-color: black;
  opacity: .3;
}

.top {
  top: 0;
  height: var(--y-percentage);
  left: 0;
  right: 0;
}

.bottom {
  bottom: 0;
  height: var(--y-percentage);
  left: 0;
  right: 0;
}

.right {
  bottom: var(--y-percentage);
  width: var(--x-percentage);
  top: var(--y-percentage);
  right: 0;
}

.left {
  bottom: var(--y-percentage);
  width: var(--x-percentage);
  top: var(--y-percentage);
  left: 0;
}

.instructions {
  position: absolute;
  color: white;
  bottom: -30px;
}

.fa-layers {
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  width: 1em;
}

.flash-button {
  border: none;

  color: white;
  font-size: 34px;
  outline: none;
}

.small-bolt {
  font-size: 0.5em;
  line-height: 2;
}

.fa-layers .fas {
  transform-origin: center center;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.error {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: white;
  color: black;
  direction: ltr;
  padding: 20px;
  white-space: pre-wrap;
  text-align: left;
}

.delaySvg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.delayCircle {
  stroke-dasharray: 600;
  stroke-dashoffset: 0;
  animation: delayCircleStroke 1s ease-out reverse;
}

@keyframes delayCircleStroke {
  to {
    stroke-dashoffset: 600;
  }
}
</style>
